
import axios from "axios";
import {
  IonContent,
  IonButton,
  IonPage,
  IonInput,
  IonRow,
  IonGrid,
  IonCol,
  IonLoading,
  IonAlert,
  IonFooter,
  IonCardSubtitle,
  IonText,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { defineComponent } from "vue";

type CreateNetToGross = {
  net_wage: 0;
  gross_wage: 0;
  employee_social_security_contribution: 0;
  employee_health_insurance_contribution: 0;
  employer_social_security_contribution: 0;
  employer_health_insurance_contribution: 0;
  income_tax: 0;
  less_than_min: false;
  less_than_min_msg: "";
};

export default defineComponent({
  name: "HomePage",
  props: {
    timeout: { type: Number, default: 1000 },
  },
  components: {
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonContent,
    IonInput,
    IonPage,
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    IonLoading,
    IonAlert,
    IonFooter,
    IonCardSubtitle,
    IonText,
  },
  data() {
    return {
      salary: 0,
      // response: new Array<CreateWageResponse>(),
      response: {
        body: new Array<CreateNetToGross>(),
        status: {
          code: 0,
          message: "",
        },
      },
      isOpenRef: false,
      employeeColumnTotal: 0,
      employeerColumnTotal: 0,
      socialContributionRowTotal: 0,
      healthContributionRowTotal: 0,
      total: 0,
      isOpenAlert: false,
      alertTextMessage: "",
      buttons: ["OK"],
      isActive: true,
    };
  },

  methods: {
    isOnlyNumberAllowed(evt : any) {
  evt = evt || window.event;

  let charCode = evt.which || evt.keyCode;
  if (
    (charCode > 31 && (charCode < 48 || charCode > 57)) && // Not a number
    charCode !== 46 && // Not a period (.)
    charCode !== 44 // Not a comma (,)
  ) {
    evt.preventDefault();
  } else {
    return true;
  }
},
    handleChange(item:any){
      if(item == 'AL'){
        window.location.reload()
      }
      if(item == "XK"){
        window.open("https://elif-ks.com/pagaime/","_self")
      }
    },
    formatNumber(value: any) {
      //function for changing the format of no only with 2 no after the comma.
      if (value == null) {
        return 0;
      } else {
        let num = parseFloat(value);
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    // selectAll(id: any) {
    //   const input = document.getElementById(id);
    //   console.log(input)
    //   input.focus();
    //   input.select();
    // },
    //llogarit neto ne bruto
    async calculateNetToGross() {
      this.employeeColumnTotal = 0;
      this.employeerColumnTotal = 0;
      this.socialContributionRowTotal = 0;
      this.healthContributionRowTotal = 0;
      this.total = 0;

      if (this.salary == 0) {
        this.isOpenAlert = true;
        this.alertTextMessage = "Vendosni nje vlere te vlefshme";
        return;
      }

      this.isOpenRef = true;
      try {
        let body = JSON.stringify({
          body: [
            {
              cmd: "calculatewage",
              wage: Number(this.salary.toString().replace(/,/g, '')),
              type: "net_to_gross",
            },
          ],
        });

        const { data } = await axios.post<CreateNetToGross>(
          "https://www.2rmlab.com/api/financabeta/guestservice.php",
          body
        );

        this.response = JSON.parse(JSON.stringify(data));
        this.isOpenRef = false;

        if (
          this.response &&
          this.response.status &&
          this.response.status.code == 600
        ) {
          let convertResponse = this.response.body[0];

          this.employeeColumnTotal =
            convertResponse.income_tax +
            convertResponse.employee_social_security_contribution +
            convertResponse.employee_health_insurance_contribution;
          this.employeerColumnTotal =
            convertResponse.employer_social_security_contribution +
            convertResponse.employer_health_insurance_contribution;
          this.socialContributionRowTotal =
            convertResponse.employee_social_security_contribution +
            convertResponse.employer_social_security_contribution;
          this.healthContributionRowTotal =
            convertResponse.employee_health_insurance_contribution +
            convertResponse.employer_health_insurance_contribution;
          this.total =
            convertResponse.income_tax +
            this.socialContributionRowTotal +
            this.healthContributionRowTotal;
        } else {
          this.isOpenAlert = true;
          this.alertTextMessage = this.response.status.message;

          return;
        }
      } catch (error) {
        this.isOpenAlert = true;
        this.alertTextMessage = `${error}`;
        this.isOpenRef = false;
      }
    },
    //llogarit bruto ne neto
    async calculateGrossToNet() {
      this.employeeColumnTotal = 0;
      this.employeerColumnTotal = 0;
      this.socialContributionRowTotal = 0;
      this.healthContributionRowTotal = 0;
      this.total = 0;
      if (this.salary == 0) {
        this.isOpenAlert = true;
        this.alertTextMessage = "Ju lutem vendosni nje vlere te vlefshme";
        return;
      }
      this.isOpenRef = true;
      try {
        let body = JSON.stringify({
          body: [
            {
              cmd: "calculatewage",
              wage: Number(this.salary.toString().replace(/,/g, '')),
              type: "gross_to_net",
            },
          ],
        });

        const { data } = await axios.post<CreateNetToGross>(
          "https://www.2rmlab.com/api/financabeta/guestservice.php",
          body
        );
        this.isOpenRef = false;
        this.response = JSON.parse(JSON.stringify(data));

        if (
          this.response &&
          this.response.status &&
          this.response.status.code == 600
        ) {
          let convertResponse = this.response.body[0];

          this.employeeColumnTotal =
            convertResponse.income_tax +
            convertResponse.employee_social_security_contribution +
            convertResponse.employee_health_insurance_contribution;
          this.employeerColumnTotal =
            convertResponse.employer_social_security_contribution +
            convertResponse.employer_health_insurance_contribution;
          this.socialContributionRowTotal =
            convertResponse.employee_social_security_contribution +
            convertResponse.employer_social_security_contribution;
          this.healthContributionRowTotal =
            convertResponse.employee_health_insurance_contribution +
            convertResponse.employer_health_insurance_contribution;
          this.total =
            convertResponse.income_tax +
            this.socialContributionRowTotal +
            this.healthContributionRowTotal;
        } else {
          this.isOpenAlert = true;
          this.alertTextMessage = this.response.status.message;

          return;
        }
      } catch (error) {
        this.isOpenAlert = true;
        this.alertTextMessage = `${error}`;
        this.isOpenRef = false;
      }
    },
  },
});
